import React from 'react';
import { Blank } from 'grommet-icons';

const IconLeaf = (props) => (
  <Blank viewBox="0 0 40 40" {...props}>
    <path d="M28.305,17.9032c0-5.3642-2.6428-10.3028-7.8548-14.6785L20,2.8468l-.45.378C14.3378,7.6,11.695,12.5391,11.695,17.9032c0,5.3093,2.5931,10.2006,7.7,14.5429v4.7071h1.4V32.28C25.7742,27.9792,28.305,23.1453,28.305,17.9032Zm-7.51,12.5029v-4.163L23.2279,23.81l-.99-.99L20.795,24.2634v-3.62L23.2279,18.21l-.99-.99L20.795,18.6634v-3.62L23.2279,12.61l-.99-.99L20.795,13.0634v-4.61h-1.4v4.61L17.952,11.62l-.99.99,2.4329,2.4329v3.62L17.952,17.22l-.99.99,2.4329,2.4329v3.62L17.952,22.82l-.99.99,2.4329,2.4329v4.3348c-4.2323-3.8883-6.3-8.046-6.3-12.6746,0-4.8463,2.26-9.1766,6.905-13.2217,4.6447,4.0452,6.905,8.3754,6.905,13.2217C26.905,22.4636,24.9018,26.5673,20.795,30.4061Z" />
  </Blank>
);

export default IconLeaf;
